import React, {Component} from "react";
import {signOut, updatePassword, confirmSignIn} from "aws-amplify/auth";
import {FormGroup, FormControl, ControlLabel} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./ChangePassword.css";

export default class ChangePassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            password: "",
            oldPassword: "",
            isChanging: false,
            confirmPassword: ""
        };
    }

    validateForm() {
        return (
            this.state.oldPassword.length > 0 &&
            this.state.password.length > 0 &&
            this.state.password === this.state.confirmPassword
        );
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleChangeClick = async event => {
        event.preventDefault();

        this.setState({isChanging: true});
        const props = this.props;
        try {
            if (this.props.isNewPasswordRequired) {
                console.log("Doing new password required");
                const newPassword = this.state.password;
                await confirmSignIn({
                        challengeResponse: newPassword
                    }
                );

                await signOut();
                this.props.userNeedsNewPassword(false);
                this.props.history.push("/login");
            } else {
                console.log("Doing elective password change");
                const oldPassword = this.state.oldPassword;
                const newPassword = this.state.password;
                await updatePassword({
                        oldPassword,
                        newPassword
                    }
                ).then( async () => {
                        console.log("Doing logout due to pw change");
                        props.userNeedsNewPassword(false);
                        props.userHasAuthenticated(true);
                        await signOut();
                        props.history.push("/login")
                        props.logoutRedirect();
                    }
                )

            }
        } catch (e) {
            alert(e.message);
            this.setState({isChanging: false});
        }
    };

    render() {
        return (
            <div className="ChangePassword">
                <form onSubmit={this.handleChangeClick}>
                    <FormGroup bsSize="large" controlId="oldPassword">
                        <ControlLabel>Old Password</ControlLabel>
                        <FormControl
                            type="password"
                            onChange={this.handleChange}
                            value={this.state.oldPassword}
                        />
                    </FormGroup>
                    <hr/>
                    <FormGroup bsSize="large" controlId="password">
                        <ControlLabel>New Password</ControlLabel>
                        <ControlLabel>
                            Note: at least 12 characters long, includes capital letter, lower case letter, number, and special character</ControlLabel>
                        <FormControl
                            type="password"
                            value={this.state.password}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    <FormGroup bsSize="large" controlId="confirmPassword">
                        <ControlLabel>Confirm Password</ControlLabel>
                        <FormControl
                            type="password"
                            onChange={this.handleChange}
                            value={this.state.confirmPassword}
                        />
                    </FormGroup>
                    <LoaderButton
                        block
                        type="submit"
                        bsSize="large"
                        text="Change Password"
                        loadingText="Changing…"
                        disabled={!this.validateForm()}
                        isLoading={this.state.isChanging}
                    />
                </form>
            </div>
        );
    }
}
