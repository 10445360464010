import React, {Component, Fragment} from "react";
import {fetchAuthSession, signOut} from "aws-amplify/auth";
import {Link, withRouter} from "react-router-dom";
import {Nav, Navbar, NavItem} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";
import Routes from "./Routes";
import "./App.css";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import {isCustomEditor, isCustomViewer} from "./libs/user-lib";
// import {loadReCaptcha} from 'react-recaptcha-v3';
// import config from "./config";

const store = require('store');
const STORE_KEY = 'lastAction';
const logoutTime = 60;              // in minutes
const warnTime = logoutTime - 1;    // in minutes

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            isAuthenticating: true,
            isNewPasswordRequired: false,
            currentUser: null,
            groups: "",
            pumpToDuplicate: null,
            userToDuplicate: null,
            clinicToDuplicate: null,
            trainerToDuplicate: null,
            orderToDuplicate: null,
            exemptionToDuplicate: null,
            discountToDuplicate: null,
            customerToDuplicate: null,
            versionToDuplicate: null,
            noticeToDuplicate: null,
            searchBoxValue: "",
            pageSize: '100',
            captchaToken: null,
            captchaKeyEval: null,
            rowData: [],
            rowDataUsers: [],
            rowDataEmails: [],
            rowDataTrainers: [],
            rowDataClinics: [],
            rowDataOrders: [],
            rowDataExemptions: [],
            rowDataDiscounts: [],
            rowDataCustomers: [],
            rowDataVersions: [],
            rowDataNotices: [],
        };

        this.events = [
            "load",
            "mousemove",
            "mousedown",
            "click",
            "scroll",
            "keypress"
        ];

        this.warn = this.warn.bind(this);
        this.logout = this.logout.bind(this);
        this.logoutRedirect = this.logoutRedirect.bind(this);
        this.resetTimeout = this.resetTimeout.bind(this);
        this.updateTimeOutListener();
        this.check();

        // loadReCaptcha(config.captchaSiteKey);
        //
        // // get captcha every 100 seconds
        // const self = this;
        // setInterval(function () {
        //     self.setState({captchaKeyEval: Math.random()});
        // }, 100 * 1000);
    }

    clearTimeout() {
        if (this.warnTimeout) {
            clearTimeout(this.warnTimeout);
        }
        if (this.logoutTimeout) {
            clearTimeout(this.logoutTimeout);
        }
    }

    setTimeout() {
        this.warnTimeout = setTimeout(this.warn, warnTime * 60 * 1000);
        this.logoutTimeout = setTimeout(this.logout, logoutTime * 60 * 1000);
    }

    processAlert = alertMsg => {
        if (alertMsg.includes('403')) {
            this.setTimeoutLogoutNow()
        } else {
            alert(alertMsg);
        }
    }

    setTimeoutLogoutNow() {
        setTimeout(this.logoutRedirect, 0);
    }

    getLastAction() {
        return parseInt(store.get(STORE_KEY));
    }

    setLastAction() {
        store.set(STORE_KEY, Date.now());
    }

    check() {
        const now = Date.now();
        const timeLeft = this.getLastAction() + logoutTime * 60 * 1000;
        const diff = timeLeft - now;
        const isTimeout = diff < 0;
        if (isTimeout) {
            this.setTimeoutLogoutNow();
        }
    }

    async checkNoTimer() {
        const now = Date.now();
        const timeLeft = this.getLastAction() + logoutTime * 60 * 1000;
        const diff = timeLeft - now;
        const isTimeout = diff < 0;
        console.log("Time left timer no wait " + diff);
        if (isTimeout) {
            await this.logoutRedirect();
        }
    }

    updateTimeOutListener = () => {
        let i;
        for (i in this.events) {
            window.addEventListener(this.events[i], this.resetTimeout);
        }
        this.resetTimeout();
        this.setLastAction();
    };

    resetTimeout() {
        // console.log("Reset timeout...");
        this.clearTimeout();
        this.setTimeout();
    }

    warn() {
        // do not provide any warning - just logout
        // alert("You will be logged out automatically in 1 minute.");
    }

    async logout() {
        // Send a logout request to the API
        console.log("Sending a logout request to the API...");
        this.destroy(); // Cleanup
        await this.handleLogout();
    }

    async logoutRedirect() {
        // Send a logout request to the API
        console.log("Sending a logout request to the API...");

        this.destroy(); // Cleanup
        await this.handleLogoutRedirect();
    }

    destroy() {
        this.clearTimeout();
        let i;
        for (i in this.events) {
            window.removeEventListener(this.events[i], this.resetTimeout);
        }

        // reload data
        this.setRowDataUsers([]);
        // reload data
        this.setRowData([]);
        this.setRowDataClinics([]);
        this.setRowDataTrainers([]);
        this.setRowDataEmails([]);
        this.setRowDataOrders([]);
        this.setRowDataExemptions([]);
        this.setRowDataDiscounts([]);
        this.setRowDataCustomers([]);
        this.setRowDataVersions([]);
        this.setRowDataNotices([]);
    }

    async componentDidMount() {
        try {
            await this.checkNoTimer();
            this.setLastAction();
            const userInfo = await fetchAuthSession();
            const sessionIdInfo = userInfo.tokens?.idToken?.payload;
            console.log("SESSION: " + JSON.stringify(sessionIdInfo, null, 2));
            userInfo.attributes = sessionIdInfo;
            console.log(userInfo.tokens?.idToken)
            if (userInfo.attributes && userInfo.attributes.email) {
                userInfo.attributes.email = userInfo.attributes.email.toLowerCase();
            } else {
                this.userHasAuthenticated(false);
                throw new Error("user not logged in");
            }
            this.setCurrentUser(userInfo);
            this.setUserGroups(sessionIdInfo['cognito:groups']);
            this.userHasAuthenticated(true);
            this.updateTimeOutListener();
        } catch (e) {
            if (e !== 'No current user') {
                alert(e);
            }
        }

        this.setState({isAuthenticating: false});
    }

    userHasAuthenticated = authenticated => {
        if (authenticated) {
            this.resetTimeout();
        }
        this.setState({isAuthenticated: authenticated});
    };

    userNeedsNewPassword = newPassword => {
        this.setState({isNewPasswordRequired: newPassword});
    };

    setCurrentUser = currentUser => {
        this.setState({currentUser: currentUser});
    };

    setUserGroups = groupValues => {
        this.setState({groups: groupValues});
    };

    setPumpToDuplicate = pump => {
        this.setState({pumpToDuplicate: JSON.parse(JSON.stringify(pump))});
    };

    setUserToDuplicate = user => {
        this.setState({userToDuplicate: JSON.parse(JSON.stringify(user))});
    };

    setTrainerToDuplicate = trainer => {
        this.setState({trainerToDuplicate: JSON.parse(JSON.stringify(trainer))});
    };
    setOrderToDuplicate = order => {
        this.setState({orderToDuplicate: JSON.parse(JSON.stringify(order))});
    };
    setExemptionToDuplicate = exemption => {
        this.setState({exemptionToDuplicate: JSON.parse(JSON.stringify(exemption))});
    };
    setDiscountToDuplicate = discount => {
        this.setState({discountToDuplicate: JSON.parse(JSON.stringify(discount))});
    };

    setClinicToDuplicate = clinic => {
        this.setState({clinicToDuplicate: JSON.parse(JSON.stringify(clinic))});
    };

    setCustomerToDuplicate = customer => {
        this.setState({customerToDuplicate: JSON.parse(JSON.stringify(customer))});
    };

    setVersionToDuplicate = version => {
        this.setState({versionToDuplicate: JSON.parse(JSON.stringify(version))});
    };

    setNoticeToDuplicate = notice => {
        this.setState({noticeToDuplicate: JSON.parse(JSON.stringify(notice))});
    };

    setSearchBox = searchValue => {
        this.setState({searchBoxValue: searchValue});
    };

    setPageSize = pageSize => {
        this.setState({pageSize: pageSize});
    };

    setCaptchaToken = captchaToken => {
        this.setState({captchaToken: captchaToken});
    };

    setCaptchaKeyEval = captchaKeyEval => {
        this.setState({captchaKeyEval: captchaKeyEval});
    };

    getNewCaptchaToken = captchaKeyEval => {
        this.setState({captchaKeyEval: Math.random()});
    };

    setRowData = rowData => {
        this.setState({rowData: rowData});
    };

    setRowDataUsers = rowDataUsers => {
        this.setState({rowDataUsers: rowDataUsers});
    };

    setRowDataEmails = rowDataEmails => {
        this.setState({rowDataEmails: rowDataEmails});
    };

    setRowDataClinics = rowDataClinics => {
        this.setState({rowDataClinics: rowDataClinics});
    };

    setRowDataTrainers = rowDataTrainers => {
        this.setState({rowDataTrainers: rowDataTrainers});
    };

    setRowDataOrders = rowDataOrders => {
        this.setState({rowDataOrders: rowDataOrders});
    };

    setRowDataExemptions = rowDataExemptions => {
        this.setState({rowDataExemptions: rowDataExemptions});
    };

    setRowDataDiscounts = rowDataDiscounts => {
        this.setState({rowDataDiscounts: rowDataDiscounts});
    };

    setRowDataCustomers = rowDataCustomers => {
        this.setState({rowDataCustomers: rowDataCustomers});
    };

    setRowDataVersions = rowDataVersions => {
        this.setState({rowDataVersions: rowDataVersions});
    };

    setRowDataNotices = rowDataNotices => {
        this.setState({rowDataNotices: rowDataNotices});
    };

    handleLogout = async event => {
        await signOut();
        this.userHasAuthenticated(false);
        this.props.history.push("/login");
    };

    handleLogoutRedirect = async event => {
        await signOut();
        this.userHasAuthenticated(false);
    };

    getUser() {
        try {
            return (this.state.currentUser.attributes.name);
        } catch (e) {
            return ('');
        }
    }

    render() {
        const childProps = {
            isAuthenticated: this.state.isAuthenticated,
            userHasAuthenticated: this.userHasAuthenticated,
            isNewPasswordRequired: this.state.isNewPasswordRequired,
            userNeedsNewPassword: this.userNeedsNewPassword,
            currentUser: this.state.currentUser,
            setCurrentUser: this.setCurrentUser,
            groups: this.state.groups,
            setUserGroups: this.setUserGroups,
            pumpToDuplicate: this.state.pumpToDuplicate,
            setPumpToDuplicate: this.setPumpToDuplicate,
            userToDuplicate: this.state.userToDuplicate,
            setUserToDuplicate: this.setUserToDuplicate,
            clinicToDuplicate: this.state.clinicToDuplicate,
            setClinicToDuplicate: this.setClinicToDuplicate,
            trainerToDuplicate: this.state.trainerToDuplicate,
            setTrainerToDuplicate: this.setTrainerToDuplicate,
            orderToDuplicate: this.state.orderToDuplicate,
            setOrderToDuplicate: this.setOrderToDuplicate,
            exemptionToDuplicate: this.state.exemptionToDuplicate,
            setExemptionToDuplicate: this.setExemptionToDuplicate,
            discountToDuplicate: this.state.discountToDuplicate,
            setDiscountToDuplicate: this.setDiscountToDuplicate,
            customerToDuplicate: this.state.customerToDuplicate,
            setCustomerToDuplicate: this.setCustomerToDuplicate,
            versionToDuplicate: this.state.versionToDuplicate,
            setVersionToDuplicate: this.setVersionToDuplicate,
            noticeToDuplicate: this.state.noticeToDuplicate,
            setNoticeToDuplicate: this.setNoticeToDuplicate,
            searchBoxValue: this.state.searchBoxValue,
            setSearchBox: this.setSearchBox,
            pageSize: this.state.pageSize,
            setPageSize: this.setPageSize,
            captchaToken: this.state.captchaToken,
            setCaptchaToken: this.setCaptchaToken,
            captchaKeyEval: this.state.captchaKeyEval,
            setCaptchaKeyEval: this.setCaptchaKeyEval,
            rowData: this.state.rowData,
            setRowData: this.setRowData,
            rowDataUsers: this.state.rowDataUsers,
            setRowDataUsers: this.setRowDataUsers,
            rowDataClinics: this.state.rowDataClinics,
            setRowDataClinics: this.setRowDataClinics,
            rowDataTrainers: this.state.rowDataTrainers,
            setRowDataTrainers: this.setRowDataTrainers,
            rowDataEmails: this.state.rowDataEmails,
            setRowDataEmails: this.setRowDataEmails,
            rowDataOrders: this.state.rowDataOrders,
            setRowDataOrders: this.setRowDataOrders,
            rowDataExemptions: this.state.rowDataExemptions,
            setRowDataExemptions: this.setRowDataExemptions,
            rowDataDiscounts: this.state.rowDataDiscounts,
            setRowDataDiscounts: this.setRowDataDiscounts,
            rowDataCustomers: this.state.rowDataCustomers,
            setRowDataCustomers: this.setRowDataCustomers,
            rowDataVersions: this.state.rowDataVersions,
            setRowDataVersions: this.setRowDataVersions,
            rowDataNotices: this.state.rowDataNotices,
            setRowDataNotices: this.setRowDataNotices,
            setUpdateTimeOutListener: this.updateTimeOutListener,
            getNewCaptchaToken: this.getNewCaptchaToken,
            setTimeoutLogoutNow: this.setTimeoutLogoutNow,
            logoutRedirect: this.logoutRedirect,
            processAlert: this.processAlert,
        };

        return (
            !this.state.isAuthenticating &&
            <div className="App container-fluid">
                <Navbar fluid collapseOnSelect>
                    <Navbar.Header>
                        <Navbar.Brand>
                            <Link to="/pumps">CamAPS Administration</Link>
                        </Navbar.Brand>
                        <Navbar.Toggle/>
                    </Navbar.Header>
                    <Navbar.Collapse>
                        <Nav pullRight>
                            {this.state.isAuthenticated && (!this.state.isNewPasswordRequired)
                                ? <Fragment>
                                    <LinkContainer to="/settings">
                                        <NavItem>Settings</NavItem>
                                    </LinkContainer>
                                    <NavItem onClick={this.handleLogout}>Logout</NavItem>
                                </Fragment>
                                : <Fragment>
                                    {/*<LinkContainer to="/signup">*/}
                                    {/*    <NavItem>Signup</NavItem>*/}
                                    {/*</LinkContainer>*/}
                                    <LinkContainer to="/login">
                                        <NavItem>Login</NavItem>
                                    </LinkContainer>
                                </Fragment>
                            }
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                {this.state.isAuthenticated && (!this.state.isNewPasswordRequired) &&
                <Navbar fluid collapseOnSelect inverse>
                    <Navbar.Header>
                        <Navbar.Toggle/>
                    </Navbar.Header>
                    {this.state.isAuthenticated &&
                    <Navbar.Collapse>
                        <Nav pullLeft>
                            <Fragment>
                                <LinkContainer className="" to="/pumps">
                                    <NavItem>Pumps</NavItem>
                                </LinkContainer>
                                {(isCustomEditor(this.state.currentUser, 'clinic') ||
                                    (isCustomViewer(this.state.currentUser, 'clinic'))) &&
                                <LinkContainer to="/clinics">
                                    <NavItem>Clinics</NavItem>
                                </LinkContainer>
                                }
                                {(isCustomEditor(this.state.currentUser, 'trainer') ||
                                    (isCustomViewer(this.state.currentUser, 'trainer'))) &&
                                <LinkContainer to="/trainers">
                                    <NavItem>Trainers</NavItem>
                                </LinkContainer>
                                }
                                {(isCustomEditor(this.state.currentUser, 'order') ||
                                    (isCustomViewer(this.state.currentUser, 'order'))) &&
                                <LinkContainer to="/orders">
                                    <NavItem>Orders</NavItem>
                                </LinkContainer>
                                }
                                {(isCustomEditor(this.state.currentUser, 'exemption') ||
                                    (isCustomViewer(this.state.currentUser, 'exemption'))) &&
                                <LinkContainer to="/exemptions">
                                    <NavItem>VAT exemptions</NavItem>
                                </LinkContainer>
                                }
                                {(isCustomEditor(this.state.currentUser, 'discount') ||
                                    (isCustomViewer(this.state.currentUser, 'discount'))) &&
                                <LinkContainer to="/discounts">
                                    <NavItem>Discounts</NavItem>
                                </LinkContainer>
                                }
                                {(isCustomEditor(this.state.currentUser, 'send_emails') ||
                                    (isCustomViewer(this.state.currentUser, 'send_emails'))) &&
                                <LinkContainer to="/emailstemp">
                                    <NavItem>Emails</NavItem>
                                </LinkContainer>
                                }
                                {(isCustomEditor(this.state.currentUser, 'version') ||
                                    (isCustomViewer(this.state.currentUser, 'version'))) &&
                                <LinkContainer to="/versions">
                                    <NavItem>Version</NavItem>
                                </LinkContainer>
                                }
                                {(isCustomEditor(this.state.currentUser, 'notice') ||
                                    (isCustomViewer(this.state.currentUser, 'notice'))) &&
                                <LinkContainer to="/notices">
                                    <NavItem>Notices</NavItem>
                                </LinkContainer>
                                }
                                {(isCustomEditor(this.state.currentUser, 'customer') ||
                                    (isCustomViewer(this.state.currentUser, 'customer'))) &&
                                <LinkContainer to="/customers">
                                    <NavItem>Customers</NavItem>
                                </LinkContainer>
                                }
                                {(isCustomEditor(this.state.currentUser, 'camaps_users') ||
                                    (isCustomViewer(this.state.currentUser, 'camaps_users'))) &&
                                // isUserEditor(this.state.groups) &&
                                <LinkContainer to="/users">
                                    <NavItem>Users</NavItem>
                                </LinkContainer>
                                }
                            </Fragment>
                        </Nav>
                        <Nav pullRight>
                            <Navbar.Text className="justify-content-end">
                                {this.getUser()}
                            </Navbar.Text>
                        </Nav>
                    </Navbar.Collapse>
                    }
                </Navbar>
                }
                <Routes childProps={childProps}/>
            </div>
        );
    }

}

export default withRouter(App);
