import * as globalsUsers from "../libs/globalDefUsers";
import * as userLib from "../libs/user-lib";
import {AgGridReact} from "ag-grid-react";
import {columnDefsUsers, setColDefUserForDataEntry} from "../libs/columnDefUsers";
import {validateFormUser} from "../libs/user-lib";
import * as globalDefUsers from "../libs/globalDefUsers";
import React, {Component} from "react";
import LoaderButton from "../components/LoaderButton";
import "./NewUser.css";
const API = require("../libs/awsApiWrappers");

export default class NewUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: null,
            selectedUsers: [],

            // make a copy of the JSON structure as the underlying structure is modified
            columnDefs: JSON.parse(JSON.stringify(columnDefsUsers)),

            defaultColDef: {
                resizable: true
            },

            style: {
                width: '100%',
                // height: '364px'
                height: '105px'
            },
            isAdminEditor: userLib.isAdminEditor(this.props.groups),

            rowDataUsers: []
        };

        // disable tick box
        this.state.columnDefs[0].checkboxSelection = false;

        // update column definition
        setColDefUserForDataEntry(false, this);
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        const updateData = data => {
            this.setState({rowDataUsers: data});
        };

        updateData(this.state.rowDataUsers);
    };

    componentDidUpdate() {
        if (this.gridApi) {
            this.gridApi.refreshCells();
        }
    }

    componentDidMount() {
        if (!this.props.isAuthenticated) {
            return;
        }

        if (this.props.userToDuplicate && this.props.userToDuplicate.length > 0) {
            this.setState({rowDataUsers: [this.props.userToDuplicate[0]]});
            this.setState({isAdminEditor: userLib.isAdminEditor(this.props.groups)})
        } else {
            const obj = [{[globalsUsers.emailId]: userLib.defaultUser}];
            this.setState({rowDataUsers: obj});
        }

        this.props.setUpdateTimeOutListener();
    }

    handleSubmit = async event => {
        event.preventDefault();

        this.gridApi.stopEditing();
        if (validateFormUser(this)) {
            this.setState({isLoading: true});

            try {
                const element = this.gridApi.getDisplayedRowAtIndex(0).data;

                await this.createUser(element);
                // reload data
                this.props.setRowDataUsers([]);
                this.props.history.push("/users");
            } catch (e) {
                API.handleApiErrorWithAlert(e, this);
                this.setState({isLoading: false});
            }
        }
    };

    createUser(user) {
        const object = {
            Username: user[globalDefUsers.emailId],
            UserType: user[globalDefUsers.userTypeField],
            UserAttributes: [
                {"Name": globalDefUsers.emailId, "Value": user[globalDefUsers.emailId]},
                {"Name": globalDefUsers.email_verified, "Value": "true"},
                {"Name": globalDefUsers.userName, "Value": user[globalDefUsers.userName]},
                {"Name": globalDefUsers.trainerField, "Value": user[globalDefUsers.trainerField] || " "},
                {"Name": globalDefUsers.distributorId, "Value": user[globalDefUsers.distributorId] || " "},
                {"Name": globalDefUsers.userTypeField, "Value": user[globalDefUsers.userTypeField] || " "},
                {"Name": globalDefUsers.clinicField, "Value": user[globalDefUsers.clinicField] || " "},
                {"Name": globalDefUsers.trainerField, "Value": user[globalDefUsers.trainerField] || " "},
                {"Name": globalDefUsers.trainingStatusField, "Value": user[globalDefUsers.trainingStatusField] || " "},
                {"Name": globalDefUsers.sendingEmailsField, "Value": user[globalDefUsers.sendingEmailsField] || " "},
                {"Name": globalDefUsers.usersField, "Value": user[globalDefUsers.usersField] || " "},

                {"Name": globalDefUsers.orderField, "Value": user[globalDefUsers.orderField] || " "},
                {"Name": globalDefUsers.exemptionField, "Value": user[globalDefUsers.exemptionField] || " "},
                {"Name": globalDefUsers.discountField, "Value": user[globalDefUsers.discountField] || " "},
                {"Name": globalDefUsers.versionField, "Value": user[globalDefUsers.versionField] || " "},
                {"Name": globalDefUsers.noticesField, "Value": user[globalDefUsers.noticesField] || " "},
                {"Name": globalDefUsers.customersField, "Value": user[globalDefUsers.customersField] || " "},
            ],
            UserEnabled: (user[globalDefUsers.enabledUser] === "true") || (user[globalDefUsers.enabledUser] === true)
                ? "true" : null,
        };
        return API.post("pumps", "/users", {
            body: object
        });
    }

    render() {
        return (
            <div className="NewUser">
                <form onSubmit={this.handleSubmit}>
                    <div
                        id="pumpGrid"
                        style={this.state.style}
                        className="ag-theme-balham"
                    >
                        <AgGridReact
                            columnDefs={this.state.columnDefs}
                            defaultColDef={this.state.defaultColDef}
                            onGridReady={this.onGridReady}
                            enableCellTextSelection={true}
                            singleClickEdit={true}
                            components={this.state.components}
                            rowData={this.state.rowDataUsers}
                            headerHeight={58}
                        />
                    </div>

                    <LoaderButton
                        block
                        bsStyle="primary"
                        bsSize="large"
                        // disabled={!this.validateForm()}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Create"
                        loadingText="Creating…"
                    />
                </form>
            </div>
        );
    }
}
