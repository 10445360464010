import {getUrl} from "aws-amplify/storage";

function downloadURI(uri, name) {
    let link = document.createElement("a"); // TODO this needs testing that it still works unmodified
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // delete link;
}

function handleClick (that, e)  {
    e.preventDefault();
    let ref = that;
    if (ref) {
        ref = ref.substring(7);
        getUrl({ path: "public/" + ref })
            .then(result => {
                downloadURI(result.url, ref.replace(/\//g, "_"));
            })
            .catch(err => {
                alert(err.toString())
            });
    }
}

export function render(params) {
    if (!params) {
        return ('');
    }
    let val = params.value;
    if (val) {
        if (params.value.indexOf('cad') !== -1) {
            val = "CAD log";
        } else if (params.value.indexOf('debug') !== -1) {
            val = "DEBUG log";
        } else if (params.value.indexOf('room') !== -1) {
            val = "SQLite DB ";
        }
        if (params.value.indexOf('silent') !== -1) {
            val += " silent";
        }
        return (
            <button onClick={handleClick.bind(this, params.value)}>
                {val}
            </button>
        );
    } else {
        return ('');
    }
}