import React, {Component} from "react";
import {FormGroup, FormControl, ControlLabel} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./Login.css";
import {signIn, fetchAuthSession, signOut, getCurrentUser } from "aws-amplify/auth";
import {Link} from "react-router-dom";

export default class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            email: "",
            password: ""
        };
    }

    validateForm() {
        return this.state.email.trim().length > 0 && this.state.password.trim().length > 0;
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleSubmit = async event => {
        event.preventDefault();

        this.setState({isLoading: true});

        try {
            // reload data
            this.props.setRowDataUsers([]);
            // reload data
            this.props.setRowData([]);
            this.props.setRowDataClinics([]);
            this.props.setRowDataTrainers([]);
            this.props.setRowDataEmails([]);
            this.props.setRowDataOrders([]);
            this.props.setRowDataExemptions([]);
            this.props.setRowDataDiscounts([]);
            this.props.setRowDataCustomers([]);
            this.props.setSearchBox("");

            let user = await signIn({username: this.state.email.trim(), password: this.state.password.trim()});

            if (!user.isSignedIn) {
                user = await signIn({
                    username: this.state.email.trim().toLowerCase(),
                    password: this.state.password.trim()
                });
            }
            var newPasswordRequired = false;
            if (user.nextStep && user.nextStep.signInStep) {
                newPasswordRequired = user.nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED";
            }

            if ((!user.isSignedIn) && (!newPasswordRequired)) {
                throw new Error("Could not log in");
            }
            if (!user.attributes) {
                user.attributes = {}
            }
            user.attributes.email = this.state.email.trim().toLowerCase();

            this.props.setCurrentUser(user);
            if (newPasswordRequired) {
                this.props.userNeedsNewPassword(true);
                this.props.history.push('/settings/password');
            } else {
                this.props.userNeedsNewPassword(false);
                const x = await getCurrentUser();
                console.log("USER: " + JSON.stringify(x, null, 2));
                const session = await fetchAuthSession();
                console.log("CREDENTIALS: " + JSON.stringify(session.credentials, null, 2));
                const sessionIdInfo = session.tokens.idToken.payload;
                console.log("SESSION: " + JSON.stringify(sessionIdInfo, null, 2));
                user.attributes = sessionIdInfo;
                console.log("SETTING GROUPS TO: " + JSON.stringify(sessionIdInfo['cognito:groups']));
                this.props.setUserGroups(sessionIdInfo['cognito:groups']);
                this.props.userHasAuthenticated(true);
            }
        } catch (e) {
            console.log("Exception sequence 1 " + e);
            alert(e.message);
            this.setState({isLoading: false});
            await signOut();
        }
    };

    render() {
        return (
            <div className="Login">
                <form onSubmit={this.handleSubmit}>
                    <FormGroup controlId="email" bsSize="large">
                        <ControlLabel>Email</ControlLabel>
                        <FormControl
                            autoFocus
                            type="email"
                            value={this.state.email.trim()}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    <FormGroup controlId="password" bsSize="large">
                        <ControlLabel>Password</ControlLabel>
                        <FormControl
                            value={this.state.password}
                            onChange={this.handleChange}
                            type="password"
                        />
                    </FormGroup>
                    <Link to="/login/reset">Forgot password?</Link>
                    <LoaderButton
                        block
                        bsSize="large"
                        disabled={!this.validateForm()}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Login"
                        loadingText="Logging in…"
                    />
                </form>
            </div>
        );
    }
}
