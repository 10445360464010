import {get as oget, put as oput, del as odel, post as opost} from "aws-amplify/api";
import React from "react";
import {locale, versionID} from "./globalDefVersions";

/**
 * Wrapper for the get function call
 * @param api
 * @param path
 * @param params
 * @returns {Promise<*>}
 */
export async function get(api, path, params) {
    params.queryParams = params.queryStringParameters
    const { body } = await oget( { apiName: api, path: path, options: params } ).response;
    return body.json();
}

/**
 * Wrapper for the put function call
 * @param api
 * @param path
 * @param params
 * @returns {Promise<*>}
 */
export async function put(api, path, params) {
    params.queryParams = params.queryStringParameters
    const { body } = await oput( { apiName: api, path: path, options: params } ).response;
    return body.json();
}

/**
 * Wrapper for the del function call
 * @param api
 * @param path
 * @param params
 * @returns {Promise<*>}
 */
export async function del(api, path, params) {
    params.queryParams = params.queryStringParameters
    const { body } = await odel( { apiName: api, path: path, options: params } ).response;
    return body.json();
}

/**
 * Wrapper for the post function call
 * @param api
 * @param path
 * @param params
 * @returns {Promise<*>}
 */
export async function post(api, path, params) {
    params.queryParams = params.queryStringParameters
    const { body } = await opost( { apiName: api, path: path, options: params } ).response;
    return body.json();
}

/**
 * Extract and show a response error message
 * @param e exception
 * @param thiz caller reference
 * @returns whether we should log the user out (fatal error)
 */
export function handleApiErrorWithAlert(e, thiz) {
    console.log("API error: " + JSON.stringify(e.response));

    let alertMessage = e;
    if (e.response && e.response.body) {
        try {
            const errorData = JSON.parse(e.response.body);
            if (errorData.messageUser) {
                alertMessage = errorData.messageUser;
            }
        } catch (parseError) {
            console.error("Failed to parse response body:", parseError);
        }
    }

    alert(alertMessage);
    let msg = JSON.stringify(alertMessage);
    let fatalError = msg.includes("Missing Authentication Token") || msg.includes("403");
    if (fatalError && thiz !== undefined) {
        console.log("Logging out due to fatal error");
        thiz.props.logoutRedirect();
    }
    return fatalError;
}

/**
 * Get a specialized cell renderer to reduce code boiler-plate
 * @param prefix
 * @param self
 * @param runnable
 * @param ref
 * @returns {(function(*): (*|null))|*}
 */
export function getCustomCellRenderer(prefix, self, runnable, ref) {
    return function (params) {
        if (params.value) {
            return (
                <a
                    href={`#${prefix}`}
                    onClick={(e) => {
                        e.preventDefault();
                        if (self.state.isEditor) {
                            if (typeof runnable === "function") {
                                runnable();
                            }
                            if (ref === undefined) {
                                self.props.history.push(`/${prefix}/` + params.value);
                            } else {
                                if (ref === versionID) {
                                    self.props.history.push(`/${prefix}/` +  params.data[versionID] + '_' + params.data[locale]); // ugly workaround merge to additional parameter
                                } else {
                                    self.props.history.push(`/${prefix}/` + params.data[ref]);
                                }
                            }
                        }
                    }}
                >
                    {params.value}
                </a>
            );
        }
        return null;
    };
}