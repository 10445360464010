import React, {Component} from "react";
import {Button, Glyphicon, PageHeader} from "react-bootstrap";
import "./Notices.css";
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import * as userLib from "../libs/user-lib";
import {columnDefsNotices} from "../libs/columnDefNotices";
import {noticeID} from "../libs/globalDefNotices";
const API = require("../libs/awsApiWrappers");

export default class Notices extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            users: [],
            selectedNotices: [],
            isEditor: userLib.isCustomEditor(this.props.currentUser, 'notice'),
            isViewer: userLib.isCustomViewer(this.props.currentUser, 'notice'),

            columnDefs: columnDefsNotices,

            defaultColDef: {
                sortable: true,
                filter: true,
                lockPinned: true,
                resizable: true
            },
            style: {
                width: '100%',
                height: '644px'
                // height: '364px'
                // height: '2884px'
            },
            rowSelection: "single",
            paginationPageSize: 100,
            paginationNumberFormatter: function (params) {
                return params.value.toLocaleString();
            }
        };

        this.state.columnDefs[0].checkboxSelection = true;
        const self = this;
        this.state.columnDefs[0].cellRenderer = API.getCustomCellRenderer("notices", self,
            () => { self.props.setNoticeToDuplicate(null) }, noticeID);
    }

    onGridReady = params => {
        this.gridApi = params.api;
        // this.gridColumnApi = params.columnApi;

        const updateData = data => {
            this.props.setRowDataNotices(data);
            params.api.paginationGoToPage(0);
        };

        updateData(this.props.rowDataNotices);
        const value = this.props.searchBoxValue;
        const box = document.getElementById('filter-text-box');
        if (box) {
            box.value = value;
        }
        params.api.setGridOption("quickFilterText", value);

        const valuePageSize = this.props.pageSize;
        document.getElementById("page-size").value = valuePageSize;
        this.gridApi.setGridOption("paginationPageSize", Number(valuePageSize));
    };


    onPageSizeChanged() {
        // const pxPerRow = 28;
        const value = document.getElementById("page-size").value;
        this.props.setPageSize(value);
        this.gridApi.setGridOption("paginationPageSize", Number(value));

        // const newHeight = ((Number(value) + 3) * pxPerRow).toString() + "px";
        // this.setHeight(newHeight);
    }

    onSelectionChanged() {
        this.setState({selectedNotices: this.gridApi.getSelectedRows()});
    }

    componentDidUpdate() {
        if (this.gridApi) {
            this.gridApi.refreshCells();
        }
    }

    async componentDidMount() {
        if (!this.props.isAuthenticated) {
            return;
        }

        this.setState({isEditor: userLib.isCustomEditor(this.props.currentUser, 'notice')});
        this.setState({isViewer: userLib.isCustomViewer(this.props.currentUser, 'notice')});

        if (this.props.rowDataNotices.length === 0) {
            try {
                const notices = await this.notices("");
                let tempResult = notices;
                while (tempResult.LastEvaluatedKey) {
                    tempResult = await this.notices(tempResult.LastEvaluatedKey);
                    notices.Items = notices.Items.concat(tempResult.Items);
                }
                for (let i = 0; i < notices.Items.length; i++) {
                    if (notices.Items[i].isNoticeShown === 1) {
                        notices.Items[i].isNoticeShown = 'shown'
                    } else {
                        notices.Items[i].isNoticeShown = 'no shown'
                    }
                    if (notices.Items[i].isUserConfirmedRead === 1) {
                        notices.Items[i].isUserConfirmedRead = 'confirmed'
                    } else {
                        notices.Items[i].isUserConfirmedRead = 'not confirmed'
                    }
                }
                this.props.setRowDataNotices(notices.Items);
                this.setState({isEditor: userLib.isCustomEditor(this.props.currentUser, 'notice')});
                this.setState({isViewer: userLib.isCustomViewer(this.props.currentUser, 'notice')});
            } catch (e) {
               API.handleApiErrorWithAlert(e, this);
            }
        } else {
            this.setState({isEditor: userLib.isCustomEditor(this.props.currentUser, 'notice')});
            this.setState({isViewer: userLib.isCustomViewer(this.props.currentUser, 'notice')});

        }
        this.props.setUpdateTimeOutListener();
        this.setState({isLoading: false});
    }

    notices(lastEvaluatedKey) {
        let myInit = {
            queryStringParameters: {}
        };
        if (lastEvaluatedKey && ("" !== lastEvaluatedKey.userID)) {
            myInit.queryStringParameters.ExclusiveStartKey = lastEvaluatedKey.noticeID;
            myInit.queryStringParameters.ExclusiveStartKey1 = lastEvaluatedKey.userID;
        }
        return API.get("pumps", "/notices", myInit);
    }

    onFilterTextBoxChanged() {
        if (this.gridApi) {
            const value = document.getElementById('filter-text-box').value;
            this.props.setSearchBox(value);
            this.gridApi.setGridOption("quickFilterText", value);
        }
    }

    onAddNotice() {
        this.props.setNoticeToDuplicate(null);
        this.props.history.push('/notices/new');
    }

    onDuplicateNotice() {
        this.props.setNoticeToDuplicate(this.state.selectedNotices);
        this.props.history.push('/notices/new');
    }

    onBtExport() {
        const params = {
            skipHeader: false,
            skipFooters: false,
            skipGroups: false,
            skipPinnedTop: false,
            skipPinnedBottom: false,
            allColumns: true,
            onlySelected: false,
            suppressQuotes: true,
            fileName: "CamAPS notices",
            columnSeparator: ',',
            customHeader: "CamAPS notices\n"
        };
        this.gridApi.exportDataAsCsv(params);
    }

    renderNoticesList() {
        return (
            <div style={{width: "100%", height: "100%"}}>
                <div className="pump-container">
                    <div className="page-container">
                        {this.state.isEditor ?
                            <Button className="button-add pull-left" bsStyle="primary" bsSize="small"
                                    onClick={this.onAddNotice.bind(this)}>
                                Add notice
                            </Button> : null
                        }
                        {this.state.isEditor ?
                            <Button className="button-duplicate pull-left" bsStyle="primary" bsSize="small"
                                    margin-left={20}
                                    disabled={this.state.selectedNotices.length === 0}
                                    hidden={this.state.isEditor}
                                    onClick={this.onDuplicateNotice.bind(this)}>
                                Duplicate notice
                            </Button> : null
                        }
                        <Button className="pull-right" bsStyle="info" bsSize="small"
                                onClick={this.onBtExport.bind(this)}>
                            <Glyphicon glyph="file"/> Export to Excel
                        </Button>
                    </div>
                    <div className="page-container">
                        <div className="page-size-header pull-left">
                            Page size:&nbsp;
                            <select onChange={this.onPageSizeChanged.bind(this)} id="page-size" defaultValue={'100'}>
                                {/*<select id="page-size" defaultValue={'100'}>*/}
                                <option value="10">10</option>
                                <option value="100">100</option>
                                <option value="500">500</option>
                                <option value="1000">1000</option>
                            </select>
                        </div>
                        <input className="pull-right" type="text" id="filter-text-box" placeholder="Search..."
                               onInput={this.onFilterTextBoxChanged.bind(this)}/>
                    </div>
                    <div style={{minHeight: "400px", height: "calc(100% - 30px)"}}>
                        <div
                            id="usersGrid"
                            style={this.state.style}
                            className="ag-theme-balham"
                        >
                            <AgGridReact
                                columnDefs={this.state.columnDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowSelection={this.state.rowSelection}
                                onGridReady={this.onGridReady}
                                enableCellTextSelection={true}
                                onSelectionChanged={this.onSelectionChanged.bind(this)}
                                pagination={true}
                                paginationPageSize={this.state.paginationPageSize}
                                paginationNumberFormatter={this.state.paginationNumberFormatter}
                                rowData={this.props.rowDataNotices}
                                headerHeight={58}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderLander() {
        return (
            <div className="lander">
                <h1>Access not allowed. Not authenticated</h1>
            </div>
        );
    }

    getHeader() {
        if (this.state.isLoading) {
            return ('Loading ...');
        } else if (!this.props.rowData || (this.props.rowData.length === 0)) {
            return ('CamAPS notices');
        } else {
            return ('All CamAPS notices');
        }
    }

    // getUser() {
    //     try {
    //         return ('User: ' + this.props.currentUser.attributes.name);
    //     } catch (e) {
    //         return ('');
    //     }
    // }

    renderNotices() {
        return (
            <div className="pumps">

                {/*<div className="left-header pull-left">*/}
                <PageHeader>
                    <div className="header">
                        <div className="left-header pull-left">
                            {this.getHeader()}
                        </div>
                        {/*<div className="pull-right">*/}
                        {/*    <h4 className="text-right">*/}
                        {/*        {this.getUser()}*/}
                        {/*    </h4>*/}
                        {/*</div>*/}
                        <div className="clearfix"/>
                    </div>
                </PageHeader>
                {!this.state.isLoading && this.renderNoticesList()}
            </div>
        );
    }

    render() {
        return (
            <div className="Home">
                {this.props.isAuthenticated ? this.renderNotices() : this.renderLander()}
            </div>
        );
    }
}
