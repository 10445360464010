import React, {Component} from "react";
import LoaderButton from "../components/LoaderButton";
import "./Version.css";
import * as columnDefsVersions from "../libs/columnDefVersions";
import {AgGridReact} from "ag-grid-react";
import * as userLib from "../libs/user-lib";
import {validateFormVersion} from "../libs/user-lib";
const API = require("../libs/awsApiWrappers");

export default class Version extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: null,
            isDeleting: null,
            version: null,

            // make a copy of the JSON structure as the underlying structure is modified
            columnDefs: JSON.parse(JSON.stringify(columnDefsVersions.columnDefsVersions)),

            defaultColDef: {
                resizable: true
            },

            style: {
                width: '100%',
                // height: '364px'
                height: '140px'
            },

            isEditor: userLib.isCustomEditor(this.props.currentUser, 'version'),

            rowData: []
        };

        // disable tick box
        this.state.columnDefs[0].checkboxSelection = false;

        // update column definition
        columnDefsVersions.setColDefVersionDataEntry(true, this);
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        const updateData = data => {
            this.setState({rowData: data});
        };

        updateData(this.state.rowData);
    };

    componentDidUpdate() {
        if (this.gridApi) {
            this.gridApi.refreshCells();
        }
    }

    async componentDidMount() {
        try {
            const version = await this.getVersion();
            this.setState({rowData: [version]});
        } catch (e) {
            API.handleApiErrorWithAlert(e, this);
        }
        this.props.setUpdateTimeOutListener();
    }

    getVersion() {
        let path = this.props.match.params.id;
        path = path.replace(/\(/g, '%28').replace(/\)/g, '%29');
        return API.get("pumps", `/versions/${path}`, {});
    }

    saveVersion(version) {
        let attrib;
        for (attrib in version) {
            if (Object.prototype.hasOwnProperty.call(version, attrib)) {
                if ("" === attrib) {
                }
                attrib = null;
            }
        }
        let path = this.props.match.params.id;
        path = path.replace(/\(/g, '%28').replace(/\)/g, '%29');
        return API.put("pumps", `/versions/${path}`, {
            body: version
        });
    }

    deleteVersion() {
        const options = {deleteItem: true};
        let path = this.props.match.params.id;
        path = path.replace(/\(/g, '%28').replace(/\)/g, '%29');
        return API.put("pumps", `/versions/${path}`, {
            body: options
        });
        // return API.del("pumps", `/versions/${this.props.match.params.id}`, {});
    }

    handleSubmit = async event => {

        event.preventDefault();
        this.gridApi.stopEditing();
        if (validateFormVersion(this)) {
            this.setState({isLoading: true});
            let element;
            try {
                element = this.gridApi.getDisplayedRowAtIndex(0).data;
                await this.saveVersion(element);
                // reload data
                this.props.setRowDataVersions([]);
                this.props.history.push("/versions");
            } catch (e) {
                API.handleApiErrorWithAlert(e, this);
                this.setState({isLoading: false});
            }
        }
    };

    handleDelete = async event => {
        event.preventDefault();

        const confirmed = window.confirm(
            "Are you sure you want to delete this version? This cannot be undone."
        );

        if (!confirmed) {
            return;
        }

        this.setState({isDeleting: true});

        try {
            await this.deleteVersion();
            this.props.setRowDataVersions([]);
            this.props.history.push("/versions");
        } catch (e) {
            API.handleApiErrorWithAlert(e, this);
            this.setState({isDeleting: false});
        }
    };

    render() {
        return (
            <div className="Pumps">
                {this.state.rowData &&
                <form onSubmit={this.handleSubmit}>
                    <div
                        id="pumpGrid"
                        style={this.state.style}
                        className="ag-theme-balham"
                    >
                        <AgGridReact
                            columnDefs={this.state.columnDefs}
                            defaultColDef={this.state.defaultColDef}
                            getRowHeight={this.state.getRowHeight}
                            onGridReady={this.onGridReady}
                            enableCellTextSelection={true}
                            singleClickEdit={true}
                            // components={this.state.components}
                            rowData={this.state.rowData}
                            headerHeight={58}
                        />
                    </div>
                    {this.state.isEditor &&
                    <LoaderButton
                        block
                        bsStyle="primary"
                        bsSize="large"
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Save"
                        loadingText="Saving…"
                    />
                    }
                    {this.state.isEditor &&
                    <LoaderButton
                        block
                        bsStyle="danger"
                        bsSize="large"
                        isLoading={this.state.isDeleting}
                        onClick={this.handleDelete}
                        text="Delete"
                        loadingText="Deleting…"
                    />
                    }
                </form>}
            </div>
        );
    }
}
