import * as globals from "./globalDef";

/**
 * Shared ag-grid column definition
 * @type {*[]}
 */
export const columnDefs = [{
    headerName: "Pump SN", field: globals.pumpSN,
    pinned: 'left',
    width: 150,
}, {
    headerName: "Distributor ID", field: globals.distributorId,
    width: 120,
}, {
    headerName: "Distributor name", field: globals.distributorName,
    width: 180,
}, {
    headerName: "Start date", field: globals.startDate,
    width: globals.standardColWidth,
}, {
    headerName: "End date", field: globals.expiryDate,
    width: globals.standardColWidth
}, {
    headerName: "Extendable", field: globals.extendable,
    width: 105,
    cellStyle: {textAlign: 'center'},
    cellRenderer: checkBoxRenderer
}, {
    headerName: "Days left", field: globals.remainingDays,
    width: globals.standardColWidth,
    cellClassRules: {
        'rag-red': function (params) {
            return params.value <= 0
        }
    }
}, {
    headerName: "Make", field: globals.pumpMake,
    width: globals.standardColWidth
}, {
    headerName: "Phone model", field: globals.phoneModel,
    width: 450
}, {
    headerName: "CGM", field: globals.transmitter,
    width: 5 * globals.standardColWidth
}, {
    headerName: "Payment model", field: globals.paymentModel,
    width: 130,
}, {
    headerName: "Site", field: globals.site,
    width: globals.standardColWidth
}, {
    headerName: "Variant", field: globals.variant,
    width: globals.standardColWidth
}, {
    headerName: "Trained", field: globals.isTrained,
    width: globals.standardColWidth,
    cellStyle: {textAlign: 'center'},
    cellRenderer: checkBoxRenderer
}, {
    headerName: "Comment", field: globals.comment,
}, {
    headerName: "Created", field: globals.createdAt,
}, {
    headerName: "Modified", field: globals.modifiedAt,
}, {
    headerName: "Last edited by", field: globals.userEmail,
}, {
    headerName: "App version", field: globals.appVersion,
    width: globals.standardColWidth + 20
},
];

/**
 * Update column definition for data entry
 */
export function setColDefForDataEntry(editting, self) {
// remove items which not entered
    let field;
    let i;
    for (i = self.state.columnDefs.length - 1; i >= 0; i--) {
        field = self.state.columnDefs[i];
        if ((field.field === globals.createdAt) ||
            (field.field === globals.modifiedAt) ||
            (field.field === globals.userEmail)) {
            self.state.columnDefs.splice(i, 1);
        } else if (field.field === globals.extendable) {
            doCheckbox(field)
        } else if (field.field === globals.inHclPilot) {
            doCheckbox(field)
        } else if (field.field === globals.isTrained) {
            doCheckbox(field)
        } else if (field.field === globals.commercial) {
            doCheckbox(field)
        } else if (field.field === globals.freeTrial) {
            doCheckbox(field)
        } else if (field.field === globals.cancelled) {
            doCheckbox(field)
        } else if (field.field === globals.variant) {
            field.cellEditor = "agSelectCellEditor";
            field.cellEditorParams = {
                values: ['FX', 'HX', 'FX HX']
            };
        } else if (field.field === globals.pumpMake) {
            field.cellEditor = "agSelectCellEditor";
            field.cellEditorParams = {
                values: ['Dana-i', 'Dana RS', 'Dana R', 'YpsoPump']
            };
        } else if (field.field === globals.paymentModel) {
            field.cellEditor = "agSelectCellEditor";
            field.cellEditorParams = {
                values: ['Access fee', 'FREE/demo', 'Fee Germany', 'Fee UK',  'Fee Ireland', 'Fee France', 'Fee France in 6 months', 'Fee ATUK', 'Fee NHS']
            };
        } else if (field.field === globals.supplier) {
            field.cellEditor = "agSelectCellEditor";
            field.cellEditorParams = {
                values: ['CamDiab']
            };
        } else if (field.field === globals.omitTamper) {
            doCheckbox(field);
        } else if (field.field === globals.study) {
            field.cellEditor = "agSelectCellEditor";
            field.cellEditorParams = {
                values: ['CLOuD', 'KidsAP02', 'Dan05', 'Dan06', 'AiDAPT', 'APhome04', 'AP-Renal', 'AP-Renal phase 2',
                    'Fast-Kids', 'POP-LOOP', 'CLEAR', 'COYOTE', 'Other', 'None']
            };
            
        } else if (field.field === globals.owner) {
            field.cellEditor = "agSelectCellEditor";
            field.cellEditorParams = {
                values: ['',
                    // 'Candice Ward',
                    'Carol Edmonds']
            };
        } else if (field.field === globals.currency) {
            field.cellEditor = "agSelectCellEditor";
            field.cellEditorParams = {
                values: ['GBP', 'EUR']
            };
        } else if (field.field === globals.costModel) {
            field.cellEditor = "agSelectCellEditor";
            field.cellEditorParams = {
                values: ['monthly', 'six monthly', 'annual']
            };
        } else if ((field.field === globals.startDate) || (field.field === globals.expiryDate)) {
            // field.cellEditor = "datePicker";
            field.onCellValueChanged = self.recalculateRemainingDays;
        }
        if (field.field !== globals.remainingDays) {
            if (editting) {
                if ((field.field !== globals.pumpSN) &&
                    (field.field !== globals.extendable) &&
                    (field.field !== globals.inHclPilot) &&
                    (field.field !== globals.omitTamper) &&
                    (field.field !== globals.isTrained) &&
                    (field.field !== globals.commercial) &&
                    (field.field !== globals.cancelled) &&
                    (field.field !== globals.freeTrial)) {
                    field.editable = true;
                }
            } else {
                if ((field.field !== globals.extendable) &&
                    (field.field !== globals.inHclPilot) &&
                    (field.field !== globals.omitTamper) &&
                    (field.field !== globals.isTrained) &&
                    (field.field !== globals.commercial) &&
                    (field.field !== globals.cancelled) &&
                    (field.field !== globals.freeTrial)) {
                    field.editable = true;
                }
            }
        }
    }
}


// render a checkbox (not editable)
export function checkBoxRenderer(params) {
    return (
        <input
            type="checkbox"
            checked={params.value && params.value !== "false"}
            disabled={true}
            onChange={(event) => {
                const updatedValue = event.target.checked;
                params.node.setDataValue(params.column.colId, updatedValue);
                params.node.data.enabled = updatedValue;
            }}
        />
    );
}

// render a checkbox (editable)
export function checkBoxRendererEditable(params) {
    return (
        <input
            type="checkbox"
            checked={params.value && params.value !== "false"}
            disabled={false}
            onChange={(event) => {
                const updatedValue = event.target.checked;
                params.node.setDataValue(params.column.colId, updatedValue);
                params.node.data.enabled = updatedValue;
            }}
        />
    );
}

// set the field cell renderer to be an editable checkbox
function doCheckbox(field) {
    field.cellRenderer = checkBoxRendererEditable;
}

