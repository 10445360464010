import * as globals from "./globalDef";
import {render} from "../containers/ReferenceFormatter";

/**
 * Ag-grid column definition for details about pump
 * @type {*[]}
 */
export const columnDefsDetails = [{
    headerName: "Pump SN", field: globals.pumpSN,
    pinned: 'left',
    width: 150,
}, {
    headerName: "User ID", field: globals.userId,
    width: 230,
}, {
    headerName: "Nickname", field: globals.nickname,
    width: 150,
}, {
    headerName: "Record date (GMT)", field: globals.dateUsed,
    width: 150
}, {
    headerName: "CL started date (local)", field: globals.dateClStarted,
    width: 150
}, {
    headerName: "App version", field: globals.appVersion,
    width: globals.standardColWidth
}, {
    headerName: "App variant", field: globals.appVariant,
    width: globals.standardColWidth
}, {
    headerName: "Phone model", field: globals.phoneModel,
    width: 330
}, {
    headerName: "CGM", field: globals.transmitter,
    width: 5 * globals.standardColWidth
}, {
    headerName: "Phone locale", field: globals.locale,
    width: globals.standardColWidth
}, {
    headerName: "Country", field: globals.country,
    width: 150
}, {
    headerName: "Age category", field: globals.ageCategory,
    width: globals.standardColWidth
},
];

/**
 * Ag-grid column definition for log table
 * @type {*[]}
 */
export const columnDefsLogs = [
    {
        headerName: "Date/time", field: globals.dateTime,
        width: globals.standardColWidthLogs
    }, {
        headerName: "CAD log", field: globals.cadLog,
        cellRenderer: render,
        width: globals.standardColWidthLogs
    }, {
        headerName: "Debug log", field: globals.debugLog,
        cellRenderer: render,
        width: globals.standardColWidthLogs
    }, /*{
        headerName: "SQLite DB", field: globals.database,
        cellRendererFramework: ReferenceFormatter,
        width: globals.standardColWidthLogs
    }*/
];
