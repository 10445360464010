import React, {Component} from "react";
import LoaderButton from "../components/LoaderButton";
import "./NewDiscount.css";
import * as columnDefsDiscounts from "../libs/columnDefDiscounts";
import {AgGridReact} from "ag-grid-react";
import * as userLib from "../libs/user-lib";
import {validateFormDiscount} from "../libs/user-lib";
import * as globalsDiscount from "../libs/globalDefDiscounts";
const API = require("../libs/awsApiWrappers");

export default class NewDiscount extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: null,
            selectedDiscounts: [],

            // make a copy of the JSON structure as the underlying structure is modified
            columnDefs: JSON.parse(JSON.stringify(columnDefsDiscounts.columnDefsDiscounts)),

            defaultColDef: {
                resizable: true
            },
            style: {
                width: '100%',
                // height: '364px'
                height: '110px'
            },

            isEditor: userLib.isCustomEditor(this.props.currentUser, 'discount'),
            rowData: []
        };

        // disable tick box
        this.state.columnDefs[0].checkboxSelection = false;

        // update column definition
        columnDefsDiscounts.setColDefDiscountForDataEntry(false, this);
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        const updateData = data => {
            this.setState({rowData: data});
        };

        updateData(this.state.rowData);
    };

    componentDidUpdate() {
        if (this.gridApi) {
            this.gridApi.refreshCells();
        }
    }

    componentDidMount() {
        if (!this.props.isAuthenticated) {
            return;
        }
        this.props.setUpdateTimeOutListener();
        this.setState({isEditor: userLib.isCustomEditor(this.props.currentUser, 'discount')});

        if (this.props.discountToDuplicate && this.props.discountToDuplicate.length > 0) {
            this.setState({rowData: [this.props.discountToDuplicate[0]]});
        } else {
            const obj = [{[globalsDiscount.discountID]: userLib.defaultDiscount}];
            this.setState({rowData: obj});
        }
    }

    createDiscount(discount) {
        let attrib;
        for (attrib in discount) {
            if (Object.prototype.hasOwnProperty.call(discount, attrib)) {
                if ("" === attrib) {
                }
                attrib = null;
            }
        }
        // add captcha token
        discount.captchaToken = this.props.captchaToken;

        return API.post("pumps", "/discounts", {
            body: discount
        });
    }

    handleSubmit = async event => {
        event.preventDefault();

        this.gridApi.stopEditing();
        if (validateFormDiscount(this)) {
            this.setState({isLoading: true});
            let element;
            try {
                element = this.gridApi.getDisplayedRowAtIndex(0).data;
                await this.createDiscount(element);
                // reload data
                this.props.setRowDataDiscounts([]);
                this.props.history.push("/discounts");
            } catch (e) {
                API.handleApiErrorWithAlert(e, this);
                this.setState({isLoading: false});
            }
            this.props.getNewCaptchaToken();
        }
    };

    render() {
        return (
            <div className="NewDiscount">
                <form onSubmit={this.handleSubmit}>
                    <div
                        id="pumpGrid"
                        style={this.state.style}
                        className="ag-theme-balham"
                    >
                        <AgGridReact
                            columnDefs={this.state.columnDefs}
                            defaultColDef={this.state.defaultColDef}
                            getRowHeight={this.state.getRowHeight}
                            onGridReady={this.onGridReady}
                            enableCellTextSelection={true}
                            singleClickEdit={true}
                            rowData={this.state.rowData}
                            // headerHeight={58}
                        />
                    </div>

                    <LoaderButton
                        block
                        bsStyle="primary"
                        bsSize="large"
                        // disabled={!this.validateForm()}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Create"
                        loadingText="Creating…"
                    />
                </form>
            </div>
        );
    }
}
