import React from "react";
import ReactDOM from "react-dom";
import {Amplify} from "aws-amplify";
import {BrowserRouter as Router} from "react-router-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import config from "./config";
import "./index.css";
import {CookiesProvider} from 'react-cookie';
import * as ReactGA from 'react-ga';

ReactGA.initialize('G-BCRJCLQ4JT');

Amplify.configure({
    Auth: {
        Cognito: {
            mandatorySignIn: true,
            region: config.cognito.REGION,
            userPoolId: config.cognito.USER_POOL_ID,
            identityPoolId: config.cognito.IDENTITY_POOL_ID,
            userPoolClientId: config.cognito.APP_CLIENT_ID
        }
    },
    Storage: {
        S3: {
            region: config.s3.REGION,
            bucket: config.s3.BUCKET,
            identityPoolId: config.cognito.IDENTITY_POOL_ID
        }
    },
    API: {
        REST: {
            pumps: {
                endpoint: config.apiGateway.URL,
                region: config.apiGateway.REGION
            }
        },
    }
});

ReactDOM.render(
    <CookiesProvider>
        <Router classname="router-app">
            <App/>
        </Router>,
    </CookiesProvider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
