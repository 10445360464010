import React, {Component} from "react";
import {PageHeader, Button, Glyphicon} from "react-bootstrap";
import "./Home.css";
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import * as globals from "../libs/globalDef";
import * as userLib from "../libs/user-lib";
import * as columnDefinition from "../libs/columnDef";
import {addDistributor} from "../libs/user-lib";
const API = require("../libs/awsApiWrappers");

export default class Home extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            pumps: [],
            selectedPumps: [],
            isEditor: false,

            // columnDefs: JSON.parse(JSON.stringify(columnDefinition.columnDefs)),
            columnDefs: columnDefinition.columnDefs,

            defaultColDef: {
                sortable: true,
                filter: true,
                lockPinned: true,
                resizable: true
            },
            style: {
                width: '100%',
                height: '644px'
                // height: '364px'
                // height: '2884px'
            },
            rowSelection: "single",
            paginationPageSize: 100,
            paginationNumberFormatter: function (params) {
                return params.value.toLocaleString();
            }
        };

        this.state.columnDefs[0].checkboxSelection = true;
        const self = this;
        this.state.columnDefs[0].cellRenderer = API.getCustomCellRenderer("pumps", self,
            () => { self.props.setPumpToDuplicate(null) });

    }

    onGridReady = params => {
        this.gridApi = params.api;
        // this.gridColumnApi = params.columnApi;

        const updateData = data => {
            this.props.setRowData(data);
            params.api.paginationGoToPage(0);
        };

        updateData(this.props.rowData);
        const value = this.props.searchBoxValue;
        document.getElementById('filter-text-box').value = value;
        params.api.setGridOption("quickFilterText", value);

        const valuePageSize = this.props.pageSize;
        document.getElementById("page-size").value = valuePageSize;
        params.api.setGridOption("paginationPageSize", Number(valuePageSize));
    };


    onPageSizeChanged() {
        // const pxPerRow = 28;
        const value = document.getElementById("page-size").value;
        this.props.setPageSize(value);
        this.gridApi.setGridOption("paginationPageSize", Number(value));

        // const newHeight = ((Number(value) + 3) * pxPerRow).toString() + "px";
        // this.setHeight(newHeight);
    }

    onSelectionChanged() {
        this.setState({selectedPumps: this.gridApi.getSelectedRows()});
    }

    componentDidUpdate() {
        if (this.gridApi) {
            this.gridApi.refreshCells();
        }
    }

    async componentDidMount() {
        if (!this.props.isAuthenticated) {
            return;
        }

        if (this.props.match.path === "/") {
            this.props.history.push("/pumps");
        }

       this.setState({
            distributorId:
                userLib.getCustomAttribute(this.props.currentUser, 'distributorId')
        });

       if (this.props.rowData.length === 0) {
           try {
               this.setState({
                   isAdmin: userLib.isAdminViewer(this.props.groups)
                       || userLib.isAdminEditor(this.props.groups)
               })
               const pumps = await this.pumps("");
               let tempResult = pumps;
               while (tempResult.LastEvaluatedKey) {
                   tempResult = await this.pumps(tempResult.LastEvaluatedKey);
                   pumps.Items = pumps.Items.concat(tempResult.Items);
               }
               for (let pump of pumps.Items) {
                   if (pump[globals.pumpMake]
                       && pump[globals.pumpMake].includes('Ypso')
                       && (!pump[globals.paymentModel])) {
                       pump[globals.paymentModel] = 'Access fee'
                   }
                   if (pump[globals.transmitter]) {
                       pump[globals.transmitter] = pump[globals.transmitter].replaceAll(',', ';');
                   }
               }
               this.props.setRowData(pumps.Items);

               addDistributor(this);
               userLib.calculateDaysLeftArray(pumps);
               this.setState({isEditor: userLib.isEditor(this.props.groups) || userLib.isAdminEditor(this.props.groups)})
           } catch (e) {
               API.handleApiErrorWithAlert(e, this);
           }
       } else {
           this.setState({
               isEditor: userLib.isEditor(this.props.groups) ||
                   userLib.isAdminEditor(this.props.groups)
           });
       }

        this.props.setUpdateTimeOutListener();
        this.setState({isLoading: false});
    }

    pumps(lastEvaluatedKey) {
        let myInit = {
            queryStringParameters: {}
        };
        if (lastEvaluatedKey && ("" !== lastEvaluatedKey)) {
            myInit.queryStringParameters.ExclusiveStartKey = lastEvaluatedKey[globals.pumpSN];
            if (!this.state.isAdmin) {
                myInit.queryStringParameters.ExclusiveStartKey1 = lastEvaluatedKey[globals.pumpSN];
                myInit.queryStringParameters.ExclusiveStartKey = lastEvaluatedKey.distributorId;
            }
        }
        return API.get("pumps", "/pumps", myInit);
    }

    onFilterTextBoxChanged() {
        if (this.gridApi) {
            const value = document.getElementById('filter-text-box').value;
            this.props.setSearchBox(value);
            this.gridApi.setGridOption("quickFilterText", value);
        }
    }

    onAddPump() {
        this.props.setPumpToDuplicate(null);
        this.props.history.push('/pumps/new');
    }

    onDuplicatePump() {
        this.props.setPumpToDuplicate(this.state.selectedPumps);
        this.props.history.push('/pumps/new');
    }

    onInfoPump() {
        this.props.setPumpToDuplicate(this.state.selectedPumps);
        this.props.history.push('/pumps/info/' + this.state.selectedPumps[0][globals.pumpSN]);
    }

    onBtExport() {
        const params = {
            skipHeader: false,
            skipFooters: false,
            skipGroups: false,
            skipPinnedTop: false,
            skipPinnedBottom: false,
            allColumns: true,
            onlySelected: false,
            suppressQuotes: true,
            fileName: "CamAPS allowed pumps",
            columnSeparator: ',',
            customHeader: "CamAPS allowed pumps\n"
        };
        this.gridApi.exportDataAsCsv(params);
    }

    renderPumpsList() {
        return (
            <div style={{width: "100%", height: "100%"}}>
                <div className="pump-container">
                    <div className="page-container">
                        {this.state.isEditor ?
                            <Button className="button-add pull-left" bsStyle="primary" bsSize="small"
                                    onClick={this.onAddPump.bind(this)}>
                                Add pump
                            </Button> : null
                        }
                        {this.state.isEditor ?
                            <Button className="button-duplicate pull-left" bsStyle="primary" bsSize="small"
                                    margin-left={20}
                                    disabled={this.state.selectedPumps.length === 0}
                                    hidden={this.state.isEditor}
                                    onClick={this.onDuplicatePump.bind(this)}>
                                Duplicate pump
                            </Button> : null
                        }
                        <Button className="button-info pull-left" bsStyle="primary" bsSize="small"
                                margin-left={20}
                                disabled={this.state.selectedPumps.length === 0}
                                onClick={this.onInfoPump.bind(this)}>
                            Pump usage info
                        </Button>
                        <Button className="pull-right" bsStyle="info" bsSize="small"
                                onClick={this.onBtExport.bind(this)}>
                            <Glyphicon glyph="file"/> Export to Excel
                        </Button>
                    </div>
                    <div className="page-container">
                        <div className="page-size-header pull-left">
                            Page size:&nbsp;
                            <select onChange={this.onPageSizeChanged.bind(this)} id="page-size" defaultValue={'100'}>
                                {/*<select id="page-size" defaultValue={'100'}>*/}
                                <option value="10">10</option>
                                <option value="100">100</option>
                                <option value="500">500</option>
                                <option value="1000">1000</option>
                            </select>
                        </div>
                        <input className="pull-right" type="text" id="filter-text-box" placeholder="Search..."
                               onInput={this.onFilterTextBoxChanged.bind(this)}/>
                    </div>
                    <div className="grid-wrapper">
                        <div
                            id="pumpGrid"
                            style={this.state.style}
                            className="ag-theme-balham"
                        >
                            <AgGridReact
                                columnDefs={this.state.columnDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowSelection={this.state.rowSelection}
                                onGridReady={this.onGridReady}
                                enableCellTextSelection={true}
                                onSelectionChanged={this.onSelectionChanged.bind(this)}
                                pagination={true}
                                paginationPageSize={this.state.paginationPageSize}
                                paginationNumberFormatter={this.state.paginationNumberFormatter}
                                rowData={this.props.rowData}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderLander() {
        return (
            <div className="lander">
                <h1>Administration portal</h1>
                {/*<p>Managing access to pumps for CamAPS app and other administrative tasks</p>*/}
            </div>
        );
    }

    getHeader() {
        if (this.state.isLoading) {
            return ('Loading ...');
        } else if (!this.props.rowData || (this.props.rowData.length === 0)) {
            return ('Your Pumps');
        } else if (userLib.isAdminViewer(this.props.groups) || userLib.isAdminEditor(this.props.groups)) {
            return ('All Pumps');
        } else if (this.state.distributorId === '70000') {
            return 'Ypsomed Headquarters (tech support)';
        } else {
            return (this.props.rowData[0][globals.distributorName] + ' Pumps')
        }
    }

    // getUser() {
    //     try {
    //         return ('User: ' + this.props.currentUser.attributes.name);
    //     } catch (e) {
    //         return ('');
    //     }
    // }

    renderPumps() {
        return (
            <div className="pumps">

                {/*<div className="left-header pull-left">*/}
                <PageHeader>
                    <div className="header">
                        <div className="left-header pull-left">
                            {this.getHeader()}
                        </div>
                        <div className="pull-right">
                            {/*<h4 className="text-right">*/}
                            {/*    {this.getUser()}*/}
                            {/*</h4>*/}
                        </div>
                        <div className="clearfix"/>
                    </div>
                </PageHeader>
                {!this.state.isLoading && this.renderPumpsList()}
            </div>
        );
    }

    render() {
        return (
            <div className="Home">
                {this.props.isAuthenticated ? this.renderPumps() : this.renderLander()}
            </div>
        );
    }
}
